import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Person from "../components/person"


const Team = ({data}) => {
	const images = data.teamImages.edges

  return (
    <Layout title="Team" slogan="Gemeinsam für Sie" headerImage={data.headerImage.childImageSharp.fluid}>
			<div className="person-grid">
      {images.sort( () => Math.random() - 0.5).map((element) => (
				<>
				<Person name={element.node.name} image={element.node.childImageSharp.fluid} />
				</>
      ))}
      <div className="person spacefiller"></div>
      <div className="person spacefiller"></div>
      <div className="person spacefiller"></div>
			</div>
      <div style={{marginTop: "2em"}}>
        <h3>AGS Lernende</h3>
        <p>1. Jahr: Ema Bytyqi<br/> 2. Jahr: Marya Al Dakhlalla <br/><br/> 
        {/* Praktikant/in: Nicolae Flocea */}
        </p>
      </div>
    </Layout>
  ) 
}

export default Team
export const pageQuery = graphql`
  query {
    teamImages: allFile(filter: {relativeDirectory: {eq: "team"}}, sort: {order: ASC, fields: name}) {
			edges {
				node {
					childImageSharp {
						fluid(quality: 90, maxWidth: 800) {
							...GatsbyImageSharpFluid_withWebp
						}
					},
					name
				}
			}
		}
    headerImage: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-misc-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`